import * as Types from '../../../../gql/types.generated';

import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveTimezoneMutationVariables = Types.Exact<{
  input: Types.CommonMemberUpdateInput;
}>;


export type SaveTimezoneMutation = { __typename?: 'Mutation', partnerMemberUpdate: { __typename?: 'PartnerMemberUpdatePayload', success: boolean } };


export const SaveTimezoneDocument = gql`
    mutation saveTimezone($input: CommonMemberUpdateInput!) {
  partnerMemberUpdate(input: $input) {
    success
  }
}
    `;
export type SaveTimezoneMutationFn = Apollo.MutationFunction<SaveTimezoneMutation, SaveTimezoneMutationVariables>;

/**
 * __useSaveTimezoneMutation__
 *
 * To run a mutation, you first call `useSaveTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTimezoneMutation, { data, loading, error }] = useSaveTimezoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTimezoneMutation(baseOptions?: Apollo.MutationHookOptions<SaveTimezoneMutation, SaveTimezoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTimezoneMutation, SaveTimezoneMutationVariables>(SaveTimezoneDocument, options);
      }
export type SaveTimezoneMutationHookResult = ReturnType<typeof useSaveTimezoneMutation>;
export type SaveTimezoneMutationResult = Apollo.MutationResult<SaveTimezoneMutation>;
export type SaveTimezoneMutationOptions = Apollo.BaseMutationOptions<SaveTimezoneMutation, SaveTimezoneMutationVariables>;


export function useSaveTimezoneRequest() {
    const client = useApolloClient();
    return useCallback<(variables: SaveTimezoneMutationVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<SaveTimezoneMutation>>>( (variables, options) => {
        return client.query<SaveTimezoneMutation>({
            fetchPolicy: 'no-cache',
          ...options,
          query: SaveTimezoneDocument,
          variables
        })
    }, []);
}

