import browser from '@sentry/browser';
import { useCallback, useEffect, useState } from 'react';

const WHITE_LIST = ['DEV_TOOLS_KEY', 'FINANCES-EXPORTS', 'user-timezones'];
export class LocalStorage {
  private static emitChanges(key: string, value: unknown) {
    const event = new CustomEvent<LocalStorageSubscribeEvent>(LOCAL_STORAGE_SUBSCRIBE_EVENT, {
      detail: { key, value }
    });
    document.dispatchEvent(event);
  }
  public static getItem<Type = unknown>(key: string): Type | null {
    const data = localStorage.getItem(key);

    if (!data) {
      return null;
    }
    try {
      return (JSON.parse(data)?.value as Type) || null;
    } catch (error) {
      if (process.env.REACT_APP_SENTRY_AUTH_TOKEN && browser && typeof browser?.captureException === 'function') {
        browser.captureException(error, { contexts: { LocalStorage: { getItem: key } } });
      }
      return null;
    }
  }
  public static setItem(key: string, value?: any) {
    localStorage.setItem(key, JSON.stringify({ value }));
    this.emitChanges(key, value);
  }
  public static removeItem(key: string) {
    localStorage.removeItem(key);
    this.emitChanges(key, null);
  }
  public static clearAll() {
    Object.keys(localStorage).forEach(key => {
      if (WHITE_LIST.findIndex(item => key.includes(item)) >= 0) return;
      localStorage.removeItem(key);
    });

    document.dispatchEvent(new CustomEvent(LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT));
  }

  public static subscribe<Type = unknown>(key: string, cb: (value: Type | null) => void) {
    function filterEvent(event: CustomEvent<LocalStorageSubscribeEvent>) {
      if (event.detail?.key === key) {
        cb(event.detail?.value as Type | null);
      }
    }
    function handleClear() {
      cb(null);
    }
    document.addEventListener(LOCAL_STORAGE_SUBSCRIBE_EVENT, filterEvent);
    if (WHITE_LIST.findIndex(item => key.includes(item)) < 0) {
      document.addEventListener(LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT, handleClear);
    }
    return () => {
      document.removeEventListener(LOCAL_STORAGE_SUBSCRIBE_EVENT, filterEvent);
      if (WHITE_LIST.findIndex(item => key.includes(item)) < 0) {
        document.removeEventListener(LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT, handleClear);
      }
    };
  }
}

export const LOCAL_STORAGE_SUBSCRIBE_EVENT = 'LOCAL_STORAGE_SUBSCRIBE_EVENT';
export const LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT = 'LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT';
type LocalStorageSubscribeEvent = {
  key: string;
  value: unknown;
};
declare global {
  interface DocumentEventMap {
    [LOCAL_STORAGE_SUBSCRIBE_EVENT]: CustomEvent<LocalStorageSubscribeEvent>;
  }
}

export function useLocalStorage<Type = unknown>(key: string) {
  const [value, updateValue] = useState<Type | null>(() => {
    return LocalStorage.getItem(key);
  });
  useEffect(() => {
    return LocalStorage.subscribe<Type>(key, updateValue);
  }, [key]);

  const setValue = useCallback(
    (value?: Type | null) => {
      LocalStorage.setItem(key, value);
    },
    [key]
  );

  const removeValue = useCallback(() => {
    LocalStorage.removeItem(key);
  }, [key]);

  return {
    value,
    setValue,
    removeValue
  };
}
