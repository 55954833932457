import { AddressComponent, AddressObject } from '../../lib/AddressObject';
import { FormattedAddress } from '../../lib/FormattedAddress';
import { Place } from '@/interfaces/brokrete';

interface Result {
  place_id: string;

  address_components: Array<AddressComponent>;

  formatted_address: string;

  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: { lat: number; lng: number };
      southwest: { lat: number; lng: number };
    };
  };

  types: Array<string>;
}

export class PlaceAdapter {
  private placeId!: string;
  private location!: { latitude: number; longitude: number };
  private bounds!: {
    northeast: { latitude: number; longitude: number };
    southwest: { latitude: number; longitude: number };
  };

  private mainText!: string;
  private secondaryText!: string;

  private state!: string;
  private country!: string;
  private city!: string;
  private zip!: string;

  public from(result: Result): PlaceAdapter {
    this.placeId = result.place_id;
    this.location = {
      latitude: result.geometry.location.lat,
      longitude: result.geometry.location.lng
    };

    this.bounds = {
      northeast: {
        latitude: result.geometry.viewport.northeast.lat,
        longitude: result.geometry.viewport.northeast.lng
      },
      southwest: {
        latitude: result.geometry.viewport.southwest.lat,
        longitude: result.geometry.viewport.southwest.lng
      }
    };

    const addressObject = new AddressObject(result.address_components);
    const formattedAddress = new FormattedAddress(result.formatted_address, addressObject);

    this.mainText = formattedAddress.main;
    this.secondaryText = formattedAddress.secondary;
    this.state = addressObject.state;
    this.country = addressObject.country;
    this.city = addressObject.city;
    this.zip = addressObject.zip;

    return this;
  }

  public toData(): Place {
    return {
      id: this.placeId,
      mainText: this.mainText,
      secondaryText: this.secondaryText,
      // @ts-ignore
      location:
        this.location != null
          ? {
              latitude: this.location.latitude,
              longitude: this.location.longitude
            }
          : null,
      state: this.state,
      country: this.country,
      city: this.city,
      zip: this.zip,

      ...(this.bounds && {
        bounds: this.bounds
      })
    };
  }
}
