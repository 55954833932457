import { decode, encode } from 'js-base64';
import { currentQuery } from '@/libs/searchQuery';

export const encodeQueryVariables = (variables: any) => encode(JSON.stringify(variables));

export const decodeQueryVariables: <T = Record<string, any>>() => T = () => {
  const { f } = currentQuery();

  if (f) {
    const decodedJSON = decode(f);
    return JSON.parse(decodedJSON);
  }

  return {};
};
