import { LocalStorage } from '@/core/Storage';
import { getAccoutKey } from '@/shared/access';
import moment from 'moment';

export function getUserPreferedTimezone() {
  return LocalStorage.getItem<string>(getAccoutKey('tz'));
}

export function getTimezone() {
  const cachePartner = LocalStorage.getItem<{ partner: { timezone: string } }>(getAccoutKey('account'));
  const cachedTimeZone = LocalStorage.getItem<string>(getAccoutKey('tz'));

  return cachedTimeZone || (cachePartner?.partner?.timezone as string);
}

export function getTimeZoneDiff(timezoneA: string, timezoneB: string) {
  var now = moment.utc().date();
  if (!timezoneA || !timezoneB) return 0;
  var offsetA = moment.tz.zone(timezoneA)?.utcOffset(now) || 0;
  var offsetB = moment.tz.zone(timezoneB)?.utcOffset(now) || 0;
  return offsetA - offsetB;
}

export function getBaseTimeZoneDiff() {
  const cachePartner = LocalStorage.getItem<{ partner: { timezone: string } }>(getAccoutKey('account'));
  const cachedTimeZone = getTimezone();
  if (!cachePartner?.partner?.timezone || !cachedTimeZone) return 0;
  return getTimeZoneDiff(cachePartner.partner.timezone, cachedTimeZone);
}

export function convertDate(date: string) {
  const baseTS = getBaseTimeZoneDiff();
  if (!baseTS) return date;
  return moment(date).tz(getTimezone(), true).toISOString();
}

export function formatDate(
  format: string,
  date?: string | null,
  timeZone?: string | null,
  deliveryWindow?: number,
  secondaryFormat?: string
) {
  if (!date || !timeZone) return null;
  const tz = getTimezone();
  if (!tz) {
    return {
      original: formatWithDeliveryWindow({ date, timeZone, format, deliveryWindow, secondaryFormat })
    };
  }
  if (!getTimeZoneDiff(tz, timeZone)) {
    return {
      original: formatWithDeliveryWindow({ date, timeZone, format, deliveryWindow, secondaryFormat })
    };
  }
  return {
    custom: formatWithDeliveryWindow({ date, timeZone: tz, format, deliveryWindow, secondaryFormat }),
    original: formatWithDeliveryWindow({ date, timeZone, format, deliveryWindow, secondaryFormat })
  };
}

function formatWithDeliveryWindow(options: {
  date: string;
  timeZone: string;
  format: string;
  deliveryWindow?: number;
  secondaryFormat?: string;
}) {
  if (!options.deliveryWindow) return moment.tz(options.date, options.timeZone).format(options.format);
  return `${moment.tz(options.date, options.timeZone).format(options.format)} - ${moment
    .tz(options.date, options.timeZone)
    .clone()
    .add({ hours: options.deliveryWindow })
    .format(options?.secondaryFormat || options.format)}`;
}
