import { Place } from '@/interfaces/brokrete';

interface Prediction {
  id: string;
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface NearbyPlace {
  id: string;
  place_id: string;
  geometry: { location: { lat: number; lng: number } };
  name: string;
  vicinity: string;
}

export class PlaceAdapter {
  private id!: string;
  private placeId!: string;
  private geometry!: { latitude: number; longitude: number };
  private mainText!: string;
  private secondaryText!: string;
  private description!: string;

  public fromPrediction(prediction: Prediction): PlaceAdapter {
    this.id = prediction.id;
    this.placeId = prediction.place_id;
    this.mainText = prediction.structured_formatting.main_text;
    this.secondaryText = prediction.structured_formatting.secondary_text;
    this.description = prediction.description;

    return this;
  }

  public fromNearbyPlace(nearbyPlace: NearbyPlace): PlaceAdapter {
    this.id = nearbyPlace.id;
    this.placeId = nearbyPlace.place_id;
    this.geometry = {
      latitude: nearbyPlace.geometry.location.lat,
      longitude: nearbyPlace.geometry.location.lng
    };
    this.mainText = nearbyPlace.name;
    this.secondaryText = nearbyPlace.vicinity;
    this.description = `${nearbyPlace.name}, ${nearbyPlace.vicinity}`;

    return this;
  }

  public toData(): Place {
    return {
      id: this.placeId,
      mainText: this.mainText,
      secondaryText: this.secondaryText,
      // @ts-ignore
      location:
        this.geometry != null
          ? {
              latitude: this.geometry.latitude,
              longitude: this.geometry.longitude
            }
          : null,
      // @ts-ignore
      state: null,
      // @ts-ignore
      country: null,
      // @ts-ignore
      zip: null,
      // @ts-ignore
      city: null
    };
  }
}
