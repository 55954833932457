import { ApolloLink } from '@apollo/client';
import { LocalStorage } from '@/core/Storage';
import { convertDate, getTimezone } from '@/shared/tz/getTimzone';

export const initLangLink = () => {
  return new ApolloLink((operation, forward) => {
    const tz = getTimezone();
    if (tz && !!operation?.query?.definitions?.find(item => (item as any)?.operation == 'query') && operation?.variables) {
      if (operation?.variables?.from && typeof operation?.variables?.from === 'string') {
        operation.variables.from = convertDate(operation?.variables.from);
      }
      if (operation?.variables?.to && typeof operation?.variables?.to === 'string') {
        operation.variables.to = convertDate(operation?.variables.to);
      }
    }
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'Accept-Language': LocalStorage.getItem('lang') || 'en'
      }
    }));

    return forward(operation);
  });
};
