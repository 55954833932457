import { FC, useCallback, useMemo, useState } from 'react';
import { Select } from 'antd';
import { LocalStorage, useLocalStorage } from '@/core/Storage';
import { getAccoutKey, useUserData } from '@/shared/access';
import { useGettext } from '@cranium/i18n';
import { TzModal } from '@/shared/tz/Timezone/TzModal';
import styled from 'styled-components';
import { useSaveTimezoneRequest } from '@/shared/tz/Timezone/queries/saveTimezone.generated';
const PartnerTZ = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Timezone: FC = () => {
  const { gettext } = useGettext();
  const meData = useUserData();
  const save = useSaveTimezoneRequest();
  const [open, setOpen] = useState<boolean>(false);
  const cachePartner = LocalStorage.getItem<{ partner: { timezone: string } }>(getAccoutKey('account'));
  const cachedTimeZone = LocalStorage.getItem<string>(getAccoutKey('tz'));
  const { value, setValue } = useLocalStorage<{ value: string; label: string }[]>(getAccoutKey('user-timezones'));
  const options = useMemo(() => {
    const addNew = {
      label: <b>{gettext('Add new timezone')}</b>,
      value: 'select_timezone'
    };
    const partnerTZ = {
      label: (
        <PartnerTZ>
          <b>{gettext('Partner timezone:')} </b>
          {cachePartner?.partner?.timezone}
        </PartnerTZ>
      ),
      value: cachePartner?.partner?.timezone
    };
    if (!value && !cachePartner?.partner?.timezone) {
      return [addNew];
    }
    if (!value && cachePartner?.partner?.timezone) {
      return [partnerTZ, addNew];
    }
    if (Array.isArray(value)) {
      return [
        ...(cachePartner?.partner?.timezone ? [partnerTZ] : []),
        ...value.filter(({ value }) => value !== cachePartner?.partner?.timezone),
        addNew
      ];
    }
  }, [value]);

  const handleChange = useCallback(
    async (value?: string) => {
      if (value === 'select_timezone') {
        return setOpen(true);
      }
      const id = meData?.partnerMember?.id;
      if (!value || !id) return;
      await save({ input: { workingTimezone: value, id } });
      LocalStorage.setItem(getAccoutKey('tz'), value);
      window.location.reload();
    },
    [meData?.partnerMember?.id]
  );
  return (
    <div style={{ width: '200px', display: 'flex', flexDirection: 'column' }}>
      <Select
        filterOption={(search, value) => {
          if (!search) return true;
          if (value?.value === 'select_timezone') return true;
          return !!value?.value?.toLowerCase().includes(search?.toLowerCase());
        }}
        optionLabelProp="value"
        options={options}
        showSearch
        value={cachedTimeZone || cachePartner?.partner?.timezone}
        onChange={handleChange}
      />
      <TzModal
        open={open}
        setOpen={setOpen}
        onChange={tz => {
          setValue([{ label: tz, value: tz }, ...(value || [])?.filter(item => item.value !== tz)]);
          handleChange(tz);
        }}
      />
    </div>
  );
};
