import { decorators } from '@/libs';

export abstract class Query {
  /**
   * Your API_KEY
   */
  public key: string | undefined;

  @decorators.enumerable(false)
  public output: 'json' | 'xml' = 'json';

  protected constructor(initialParams: Partial<any>) {
    Object.assign(this, initialParams);
  }

  public toQuery(): string {
    return this.objectToQuery(this);
  }

  protected transformValue(key: string, value: any): string {
    if (key === 'query') {
      return (value as string).replace(' ', '+');
    }

    // @ts-ignore
    if (key === 'location' && typeof this[key] !== 'string') {
      return [value.latitude, value.longitude].join(',');
    }

    return value;
  }

  protected objectToQuery(obj: { [key: string]: any }): string {
    return Object.keys(obj)
      .reduce<string[]>((acc, key) => {
        if (obj[key]) {
          if (typeof obj[key] === 'object') {
            if (Object.keys(obj[key]).length > 0) {
              acc.push(this.objectToQuery(obj[key]));
            }
          } else {
            acc.push(`${key}=${this.transformValue(key, obj[key])}`);
          }
        }
        return acc;
      }, [])
      .join('&');
  }
}
