import { mapper } from './legacyMapper';
export class PlaceAddressComponent {
  public number: string | undefined = '';
  public street: string | undefined = '';
  public city: string | undefined = '';
  public state: string | undefined = '';
  public zip: string | undefined = '';
  public country: string | undefined = '';
  public district: string | undefined = '';
  public region: string | undefined = '';

  constructor(addressComponents: Array<google.maps.places.AddressComponent>) {
    this.build(addressComponents);
  }

  private build(addressComponents: Array<google.maps.places.AddressComponent>) {
    addressComponents.forEach(component => {
      Object.entries(mapper).forEach(([field, config]) => {
        const hasType = component.types.find(value => config.types.indexOf(value) >= 0) != null;

        if (hasType) {
          // @ts-ignore
          this[field] = config.value(component);
        }
      });
    });
  }
}
