import { Query } from '../Query';

export class Details extends Query {
  public placeid!: string;

  public constructor(initialParams: Partial<Details>) {
    super(initialParams);

    this.name = 'details';

    Object.assign(this, initialParams);
  }
}
