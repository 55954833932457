import { Query } from '../Query';

export class DrivingDirection extends Query {
  /**
   * The address, textual latitude/longitude value, or place ID from which you wish to calculate directions.
   */
  public origin: string | undefined;

  /**
   * The address, textual latitude/longitude value, or place ID to which you wish to calculate directions.
   */
  public destination: string | undefined;

  /**
   * Transportation mode to use. Google API support for types but in current app we need only driving type
   */
  public mode: 'driving' = 'driving';

  public result_type: string | undefined;

  public constructor(initialParams: Partial<DrivingDirection>) {
    super(initialParams);

    Object.assign(this, initialParams);
  }
}
