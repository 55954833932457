import { useEffect, useRef, useState } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
//@ts-ignore
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { LocalStorage } from '@/core/Storage';
import { encode } from 'js-base64';
import { useParams } from '@/shared/navigation';

const clientInfo = encode(
  JSON.stringify({
    version: {
      platform: 'web-admin',
      version: '1.0',
      build: 1
    },
    name: 'BrokreteWebAdmin'
  })
);

export type StackConfig = {
  stackLenth: number;
  timeout: number;
};
class Stack<Item extends object> {
  configs?: StackConfig;
  timer?: ReturnType<typeof setTimeout>;
  stack: Item[] = [];
  messageCb: (item: Item) => void;
  constructor(messageCb: (item: Item) => void, configs?: StackConfig) {
    this.configs = configs;
    this.messageCb = messageCb;
  }

  add(item: Item) {
    if (!this.configs) {
      return this.messageCb(item);
    }
    this.stack.push(item);
    if (this.configs.stackLenth && this.stack.length >= this.configs.stackLenth) {
      return this.flushEvent();
    }
    if (this.configs.timeout && !this.timer) {
      this.timer = setTimeout(() => {
        this.flushEvent();
      }, this.configs.timeout);
    }
  }
  private flushEvent() {
    const messages = [...this.stack];
    this.stack = [];
    if (this.timer) {
      try {
        clearTimeout(this.timer);
      } catch {}
      this.timer = undefined;
    }
    messages.forEach(this.messageCb);
  }

  clear() {
    this.stack = [];
    if (this.timer) {
      try {
        clearTimeout(this.timer);
      } catch {}
      this.timer = undefined;
    }
  }
}

export function useSubscription<QueryMessages extends object, QueryVariables extends object>(
  subscriptionQuery: any,
  messageCb: (item: QueryMessages) => void,
  variables?: QueryVariables,
  configs?: StackConfig,
  onReopen?: () => void
) {
  const params = useParams<{ partnerId: string }>();
  const [subscriptionID, setSubscriptionID] = useState<number>(Date.now());
  const messagesStack = useRef(new Stack(messageCb, configs));
  const unsubscribe = useRef<() => void>(() => {});
  useEffect(() => {
    const token = LocalStorage.getItem(['token', params.partnerId].filter(Boolean).join('-'));
    const cable = createConsumer(
      `${(process.env.REACT_APP_API || '').replace('https://', 'wss://')}/cable?authorization=${token}&client_info=${clientInfo}`
    );

    if (onReopen) {
      const original = cable.connection.reopen;
      cable.connection.reopen = () => {
        if (original.call(cable.connection)) {
          onReopen();
        }
      };
    }

    const wsLink = new ActionCableLink({ cable, connectionParams: {} });

    const cache = new InMemoryCache();
    const client = new ApolloClient({ link: wsLink, cache: cache });

    messagesStack.current.clear();
    messagesStack.current = new Stack(messageCb, configs);
    const subscriptionClient = client.subscribe<QueryMessages>({
      query: subscriptionQuery,
      variables: variables
    });
    const subscribtion = subscriptionClient.subscribe({
      next: data => {
        if (data?.data) {
          messagesStack.current.add(data?.data);
        }
      }
    });

    unsubscribe.current = () => {
      subscribtion.unsubscribe();
      cable.connection.webSocket.close();
      cable.disconnect({ allowReconnect: false });
    };

    return () => {
      cable.connection.webSocket.close();
      subscribtion.unsubscribe();
      cable.disconnect({ allowReconnect: false });
    };
  }, [JSON.stringify(variables || null), subscriptionID]);

  return (isEnabled: Boolean) => {
    if (isEnabled) {
      setSubscriptionID(Date.now());
    } else {
      unsubscribe.current();
    }
  };
}
