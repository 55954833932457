import { FC, useEffect, useState } from 'react';
import { Modal, Form } from 'antd';
import { gettext } from '@cranium/i18n';
import { LocationInput } from '@/shared/components/dataEntry/LocationInput';
import { timezone as googleTimezoneAPI } from '@/shared/core/google';

type TzModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onChange: (tz: string) => void;
};
export const TzModal: FC<TzModalProps> = ({ open, setOpen, onChange }) => {
  const [timezone, setTimeZone] = useState<{ timezone: string; text: string } | null>(null);
  useEffect(() => {
    setTimeZone(null);
  }, [open]);
  return (
    <Modal
      okText={gettext('Add Timezone')}
      onOk={() => {
        if (!timezone?.timezone) {
          return setOpen(false);
        }
        onChange(timezone.timezone);
        setOpen(false);
      }}
      okButtonProps={{
        disabled: !timezone?.timezone
      }}
      destroyOnClose
      open={open}
      width={400}
      onCancel={() => setOpen(false)}
      title={gettext('Type your city to choose the timezone')}
    >
      <Form layout="vertical">
        <Form.Item label={gettext('Address')}>
          <LocationInput
            value={timezone?.text}
            onChange={val => {
              const location = val?.location;
              if (!location) return;
              googleTimezoneAPI.timezone(location, new Date().getDate()).then(response => {
                if (response?.timeZoneId) {
                  setTimeZone({
                    timezone: response?.timeZoneId,
                    text: `${[val?.display].filter(Boolean).join()} (${response?.timeZoneId})`
                  });
                }
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
