import { isEmpty } from '@/libs';

export const mapper: {
  [name: string]: { types: Array<string>; value: (c: google.maps.places.AddressComponent) => string | null };
} = {
  number: {
    types: ['street_number'],
    value: c => c.shortText
  },
  street: {
    types: ['street_address', 'route'],
    value: c => c.longText
  },
  city: {
    types: ['locality', 'postal_town'],
    value: c => c.shortText
  },
  state: {
    types: ['administrative_area_level_1'],
    value: c => (!isEmpty(c.shortText) ? c.shortText : c.longText)
  },
  zip: {
    types: ['postal_code'],
    value: c => c.shortText || c.longText
  },
  country: {
    types: ['country'],
    value: c => c.longText
  },
  district: {
    types: ['sublocality', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4'],
    value: c => c.longText
  },
  region: {
    types: [
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5'
    ],
    value: c => c.shortText
  }
};
