import { AllNodesFor } from './utils';
import { pick } from 'lodash';

/*
 Sample of responce to make TS checks.
 Please Note that in order to use this module with TS and there TS error, you probably need to update this responce sample
*/

const access = {
  edit: false,
  create: false,
  delete: false,
  show: true
};

export const permissionsSchema = {
  superuser: false,
  auto_collect: { show: false },
  orders: {
    ...access,
    create: true,
    status: { edit: true },
    price: access,
    chat: access,
    delivery_date: access,
    delivery_address: access,
    delivery_details: access,
    delivery_notes: access,
    plant: access,
    trucks: access,
    controls: access,
    products: access,
    options: access,
    csv: access,
    price_mode: access,
    payment_mode: access,
    taxes: access,
    projects: {
      ...access,
      notes: access
    }
  },
  widgets: access,
  activity: access,
  locations: {
    ...access,
    info: { override_currency: true },
    create: true,
    details: access,
    categories_products: { ...access, controls: { show: false } },
    settings: access,
    fees: access,
    prices: access,
    availability: { ...access, controls: { show: false }, save_button: { show: false } },
    booking: access
  },
  products: access,
  driver_tasks: {
    ...access,
    export_csv: { show: false },
    delivery_transactions: {
      create: false,
      edit: false,
      approve: { show: false },
      confirm: { show: false },
      incoming: { edit: false },
      confirmed: { edit: false },
      approved: { edit: false }
    },
    type_pickup: {
      status_delivered: {
        delete: false
      }
    }
  },
  suppliers: { ...access, partner_filter: { show: false } },
  contractors: {
    ...access,
    price_groups: access,
    balance: access,
    managers: {
      ...access,
      role: { edit: false }
    },
    saved_suppliers: access,
    transactions: access,
    personal_product_prices: access,
    payment_methods: access,
    billing_address: access
  },
  statistic: access,
  finances: {
    show: false,
    edit: false,
    partner: { show: false },
    bank_account: { show: false }
  },
  requests: { show: false },
  profile: { show: false, edit: false, partner: access, managers: access },
  block_off_availability: access,
  messages: { show: false, partner: { show: false } },
  projects: {
    ...access,
    notes: access
  },
  drivers: access,
  analytics: access,
  inventory: access,
  scheduling: access,
  taxes: access,
  pallets: access
};

export type PermissionsType = typeof permissionsSchema;

export type PermissionAccessKeys = AllNodesFor<PermissionsType>;
