import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainLayout from '@components/layout/MainLayout';

import { HubSpot } from '@/core/Hubspot';
import { PermissionsRoute, useHasPermissions, useGetClientSettings, useUserData, PermissionsGate } from '@/shared/access';
import { PartnerRoute, RedirectWithQueries } from '@/shared/navigation';

const LocationDetailsPageNew = withSuspense(
  React.lazy(() => import('@pages/LocationDetailsPage').then(module => ({ default: module.LocationDetailsPage })))
);

const PalletsPage = withSuspense(
  React.lazy(() => import('./view/pages/pallets').then(module => ({ default: module.PalletsPage })))
);
const Plants = withSuspense(React.lazy(() => import('./view/pages/Plants').then(module => ({ default: module.Plants }))));
const Messages = withSuspense(React.lazy(() => import('./view/pages/Messages').then(module => ({ default: module.Messages }))));
const Dashboards = withSuspense(React.lazy(() => import('@pages/Dashboards').then(module => ({ default: module.Dashboards }))));
const ActivityPage = withSuspense(React.lazy(() => import('./view/pages/ActivityPage')));
const InventorySettings = withSuspense(React.lazy(() => import('./view/pages/Inventory/Settings')));
const InventoryTransactions = withSuspense(
  React.lazy(() => import('./view/pages/Inventory/Transactions').then(module => ({ default: module.Transactions })))
);

const InventoryDashboard = withSuspense(
  React.lazy(() => import('./view/pages/Inventory/Dashboard').then(module => ({ default: module.Dashboard })))
);
const BlockOffAvailability = withSuspense(React.lazy(() => import('./view/pages/BlockOffAvailability')));
const DriversPage = withSuspense(
  React.lazy(() => import('./view/pages/drivers/DriversPage').then(module => ({ default: module.DriversPage })))
);
const ContractorInfo = withSuspense(
  React.lazy(() => import('./view/pages/ContractorInfo').then(module => ({ default: module.ContractorInfo })))
);
const FinanceAccountPage = withSuspense(React.lazy(() => import('./view/pages/FinancePage/FinanceAccountPage')));
const FinancePage = withSuspense(
  React.lazy(() => import('./view/pages/FinancePage').then(module => ({ default: module.FinancePage })))
);

const NewWidgetPage = withSuspense(React.lazy(() => import('./view/pages/Widgets/NewWidgetPage')));
const OrderInfoPage = withSuspense(
  React.lazy(() => import('./view/pages/OrderInfoPage').then(module => ({ default: module.OrderInfoPage })))
);
const ProductsPage = withSuspense(React.lazy(() => import('./view/pages/ProductsPage')));
const Profile = withSuspense(React.lazy(() => import('./view/pages/Profile').then(module => ({ default: module.Profile }))));
const ProjectsPage = withSuspense(React.lazy(() => import('./view/pages/ProjectsPage')));
const RequestsPage = withSuspense(React.lazy(() => import('./view/pages/RequestsPage')));
const StatisticsPage = withSuspense(
  React.lazy(() => import('./view/pages/StatisticsPage').then(module => ({ default: module.StatisticsPage })))
);
const SupplierInfo = withSuspense(
  React.lazy(() => import('./view/pages/SupplierInfo').then(module => ({ default: module.SupplierInfo })))
);
const Suppliers = withSuspense(
  React.lazy(() => import('./view/pages/Suppliers').then(module => ({ default: module.Suppliers })))
);
const TaxPage = withSuspense(React.lazy(() => import('./view/pages/TaxPage').then(module => ({ default: module.TaxPage }))));
const TermsAndConditions = withSuspense(
  React.lazy(() => import('./view/pages/TermsAndConditions').then(module => ({ default: module.TermsAndConditions })))
);
const WidgetsPage = withSuspense(React.lazy(() => import('./view/pages/Widgets/WidgetsPage')));
const ContractorsPage = withSuspense(
  React.lazy(() => import('./view/pages/ContractorsPage').then(module => ({ default: module.ContractorsPage })))
);
const OrderTransactionsScreen = withSuspense(React.lazy(() => import('./view/screens/OrderTransactionsScreen')));
import { PermissionDeniedPage } from '@pages/PermissionDeniedPage';
import { useGettext } from '@cranium/i18n';
const NewProjectScreen = withSuspense(React.lazy(() => import('./view/pages/Projects/NewProjectScreen')));
const TrucksPage = withSuspense(React.lazy(() => import('@pages/TrucksPage').then(module => ({ default: module.TrucksPage }))));
const SchedulePage = withSuspense(
  React.lazy(() => import('@pages/schedule/SchedulePage').then(module => ({ default: module.SchedulePage })))
);
const OrderCreatePage = withSuspense(
  React.lazy(() => import('@pages/OrderCreatePage').then(module => ({ default: module.OrderCreatePage })))
);
const DeliveriesPage = withSuspense(
  React.lazy(() => import('@pages/DeliveriesPage').then(module => ({ default: module.DeliveriesPage })))
);

function withSuspense(Component: React.ComponentType & any) {
  return function WithSuspense(props: any) {
    return (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default function AdminRouter() {
  const { gettext } = useGettext();
  const user = useUserData();
  const requestsAreVisible = useGetClientSettings('requests.visible');

  const ordersShow = useHasPermissions('permissions.orders.show');
  const ordersCreate = useHasPermissions('permissions.orders.create');
  const statisticShow = useHasPermissions('permissions.statistic.show');
  const locationsShow = useHasPermissions('permissions.locations.show');
  const productsShow = useHasPermissions('permissions.products.show');
  const suppliersShow = useHasPermissions('permissions.suppliers.show');
  const suppliersVisible = useGetClientSettings('suppliers.visible');
  const contractorsShow = useHasPermissions('permissions.contractors.show');
  const activityShow = useHasPermissions('permissions.activity.show');
  const messagesShow = useHasPermissions('permissions.messages.show');
  const financesShow = useHasPermissions('permissions.finances.show');
  const projectSshow = useHasPermissions('permissions.projects.show');
  const projectsCreate = useHasPermissions('permissions.projects.create');
  const widgetsShow = useHasPermissions('permissions.widgets.show');
  const taxesShow = useHasPermissions('permissions.taxes.show');
  const profileShow = useHasPermissions('permissions.profile.show');
  const block_off_availabilityShow = useHasPermissions('permissions.block_off_availability.show');

  const getMainPage = () => {
    return ordersShow ? '/orders' : null;
  };

  if (!user) {
    return null;
  }

  return (
    <MainLayout>
      <Switch>
        {ordersShow && [
          <PartnerRoute path="/(p-)?:partnerId?/orders" exact component={Dashboards} key="/orders" />,

          ...(ordersCreate
            ? [
                <PartnerRoute path="/(p-)?:partnerId?/orders/new/:orderId?" component={OrderCreatePage} key="/orders/new" />,
                <PartnerRoute
                  path="/(p-)?:partnerId?/orders/edit/:orderId?"
                  component={() => <OrderCreatePage edit />}
                  key="/orders/edit"
                ></PartnerRoute>
              ]
            : [
                <PartnerRoute
                  path="/(p-)?:partnerId?/orders/new"
                  component={() => <PermissionDeniedPage action={gettext('create an order')} />}
                  key="/orders/new"
                />,
                <PartnerRoute
                  path="/(p-)?:partnerId?/orders/new-depricated"
                  component={() => <PermissionDeniedPage action={gettext('create an order')} />}
                  key="/order/new-deprecated"
                />
              ]),

          <PartnerRoute path="/(p-)?:partnerId?/orders/:orderId" exact component={OrderInfoPage} key="/orders/:orderId" />,
          <PartnerRoute
            path="/(p-)?:partnerId?/orders/:orderId/transactions"
            exact
            component={OrderTransactionsScreen}
            key="/orders/:orderId/transactions"
          />
        ]}
        {statisticShow && <PartnerRoute path="/(p-)?:partnerId?/statistics" exact component={StatisticsPage} />}
        <PermissionsRoute
          path="/(p-)?:partnerId?/deliveries"
          exact
          component={DeliveriesPage}
          permissions={['permissions.driver_tasks.show', 'settings.driver_tasks.visible']}
          redirectTo="/"
        />
        {locationsShow && [
          <PartnerRoute
            path="/(p-)?:partnerId?/locations-new/:id/:section/:category?"
            component={LocationDetailsPageNew}
            key="/locations-new/:id/:section/:category?"
          />,
          <PartnerRoute path="/(p-)?:partnerId?/locations" component={Plants} key="/locations" />
        ]}
        {/*<PartnerRoute path="/forgot-password" exact component={ForgotPasswordScreen} />*/}
        {productsShow && <PartnerRoute path="/(p-)?:partnerId?/products" exact component={ProductsPage} />}
        {suppliersShow &&
          suppliersVisible && [
            <PartnerRoute path="/(p-)?:partnerId?/suppliers" exact component={Suppliers} key="/suppliers" />,
            <PartnerRoute path="/(p-)?:partnerId?/suppliers/:id" exact component={SupplierInfo} key="/suppliers/:id" />
          ]}
        {contractorsShow && [
          <PartnerRoute path="/(p-)?:partnerId?/contractors" exact component={ContractorsPage} key="/contractors" />,
          <PartnerRoute path="/(p-)?:partnerId?/contractors/:id" exact component={ContractorInfo} key="/contractors/:id" />
        ]}
        <PermissionsRoute
          path="/(p-)?:partnerId?/operators"
          exact
          component={DriversPage}
          permissions="permissions.drivers.show"
          redirectTo="/"
        />
        <PermissionsRoute
          path="/(p-)?:partnerId?/inventory/settings/:settingType?"
          exact
          component={InventorySettings}
          permissions="permissions.inventory.show"
          redirectTo="/"
        />
        <PermissionsRoute
          path="/(p-)?:partnerId?/inventory/transactions/:type?"
          exact
          component={InventoryTransactions}
          permissions="permissions.inventory.show"
          redirectTo="/"
        />
        <PermissionsRoute
          path="/(p-)?:partnerId?/inventory/dashboard"
          exact
          component={InventoryDashboard}
          permissions="permissions.inventory.show"
          redirectTo="/"
        />

        <PermissionsRoute
          path="/(p-)?:partnerId?/schedule"
          exact
          component={() => <RedirectWithQueries to="/schedule/operators" />}
          permissions={['permissions.scheduling.show']}
          redirectTo="/orders"
          key="/schedule_root"
        />
        <PermissionsRoute
          path="/(p-)?:partnerId?/schedule/:section"
          exact
          component={SchedulePage}
          permissions={['permissions.scheduling.show']}
          redirectTo="/"
          key="/schedule"
        />
        {activityShow && <PartnerRoute path="/activity" exact component={ActivityPage} />}
        {messagesShow && [
          <PartnerRoute path="/(p-)?:partnerId?/messages" component={Messages} key="/messages" />,
          <PartnerRoute path="/(p-)?:partnerId?/messages/:id" component={Messages} key="/messages/:id" />
        ]}
        {financesShow && [
          <PartnerRoute path="/(p-)?:partnerId?/finance" exact component={FinancePage} key="/finance" />,
          <PartnerRoute path="/(p-)?:partnerId?/finance/account" exact component={FinanceAccountPage} key="/finance/account" />
        ]}
        {projectSshow && [
          <PartnerRoute path="/(p-)?:partnerId?/projects" exact component={ProjectsPage} key="/projects" />,

          ...(projectsCreate
            ? [<PartnerRoute path="/(p-)?:partnerId?/projects/new/:section?" component={NewProjectScreen} key="/projects/new" />]
            : [
                <PartnerRoute
                  path="/(p-)?:partnerId?/projects/new/:section?"
                  component={() => <PermissionDeniedPage action={gettext('create a project')} />}
                  key="/projects/new"
                />
              ]),

          <PartnerRoute path="/(p-)?:partnerId?/projects/:id/:section?" key="/projects/:id" component={NewProjectScreen} />
        ]}
        {widgetsShow && [
          <PartnerRoute path="/(p-)?:partnerId?/widgets" exact component={WidgetsPage} key="/widgets" />,
          <PartnerRoute path="/(p-)?:partnerId?/widgets/new" component={NewWidgetPage} key="/widgets/new" />,
          <PartnerRoute path="/(p-)?:partnerId?/widgets/:id" component={NewWidgetPage} key="/widgets/:id" />
        ]}
        {taxesShow && [<PartnerRoute path="/(p-)?:partnerId?/tax" exact component={TaxPage} key="/tax" />]}
        <PartnerRoute path="/(p-)?:partnerId?/trucks" exact component={TrucksPage} key="/trucks" />
        {profileShow && <PartnerRoute path="/(p-)?:partnerId?/profile/:tab?" component={Profile} />}
        {block_off_availabilityShow && (
          <PartnerRoute path="/(p-)?:partnerId?/availability/block-off" exact component={BlockOffAvailability} />
        )}
        {requestsAreVisible && [
          <PermissionsRoute
            permissions="permissions.requests.show"
            redirectTo="/"
            path="/(p-)?:partnerId?/requests"
            exact
            component={RequestsPage}
          />,
          <PermissionsRoute
            permissions="permissions.requests.show"
            redirectTo="/"
            path="/(p-)?:partnerId?/requests/:section"
            component={RequestsPage}
          />
        ]}
        <PermissionsRoute
          path="/(p-)?:partnerId?/pallets"
          exact
          component={PalletsPage}
          permissions="permissions.pallets.show"
          redirectTo="/"
        />
        <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
        <Route
          component={() => {
            const redirect = getMainPage();
            return redirect ? <Redirect to={redirect} /> : null;
          }}
        />
      </Switch>
      {process.env.REACT_APP_HUBSPOT_API ? <HubSpot /> : null}
    </MainLayout>
  );
}
