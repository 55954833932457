import { FC, useMemo } from 'react';
import { TimePicker as AndtTimePicker, TimePickerProps, Tooltip } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { usePartnerData } from '@/shared/access';
import { getTimezone, getTimeZoneDiff } from '@/shared/tz/getTimzone';
import { useGettext } from '@cranium/i18n';

const StyledTime = styled(AndtTimePicker)`
  .ant-picker-suffix {
    font: var(--font-regular-10);
    color: var(--gray-8);
  }
`;

type TZTimePickerProps = TimePickerProps & {
  timezone?: string | null;
  format: string;
};

export const TimePicker: FC<TZTimePickerProps> = ({ timezone, ...props }) => {
  const parterData = usePartnerData();
  const { gettext } = useGettext();
  const tz = useMemo(() => {
    if (!props.value) return null;
    const tz = timezone || parterData?.timezone;

    if (!tz) return null;
    if (!getTimeZoneDiff(tz, props.value.zoneName())) {
      return null;
    }
    return moment.tz(props.value, tz);
  }, [props.value, timezone, parterData?.timezone]);
  return (
    <Tooltip
      destroyTooltipOnHide
      title={
        tz && props.value ? (
          <table>
            <tr>
              <td>{gettext('Selected timezone')}</td>
              <td>
                {props.value?.format('L LT z ')}
                {(props.value as any)?.tz()}
              </td>
            </tr>
            <tr>
              <td>{gettext('Original timezone')}</td>
              <td>
                {tz?.format('L LT z ')}
                {tz.tz()}
              </td>
            </tr>
          </table>
        ) : null
      }
    >
      <div>
        <StyledTime
          {...props}
          suffixIcon={tz ? <>{tz?.format(props.format)}</> : null}
          onChange={(val, data) => {
            props.onChange && props.onChange(val ? (moment(val as any).tz(getTimezone(), true) as any) : val, data);
          }}
        />
      </div>
    </Tooltip>
  );
};
