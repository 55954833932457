import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { decodeQueryVariables } from '@/libs/encodedQuery';
import { useSetQueryParams } from '@/view/hooks/useSetQueryParams';
import omit from 'lodash/omit';

export type OptionsType = {
  omit?: string[];
};
export const useQueryPrams: <T = Record<string, any>>(
  _options?: OptionsType
) => [T, (values: T | ((values: T) => T), replace?: boolean) => void] = _options => {
  const location = useLocation();

  const query = useMemo<any>(() => omit(decodeQueryVariables(), _options?.omit || []), [location]);

  const setQueryParams = useSetQueryParams();

  return [query, setQueryParams];
};
