import { Query } from '../../Query';
import { Location } from '@/gql/types.generated';

export class Timezone extends Query {
  public location!: Location;
  public timestamp!: number;

  public constructor(initialParams: Partial<Timezone>) {
    super(initialParams);

    this.name = 'timezone';

    Object.assign(this, initialParams);
  }
}
