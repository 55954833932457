import { Address } from '@/gql/types.generated';
import { isEmpty, notEmpty } from '@/libs';
import { PlaceAddressComponent } from '@shared-core/google/lib/PlaceAddressComponent';

export const buildInputQuery = (value?: Partial<Address> | null) => {
  if (!value) {
    return '';
  }

  return value.display ? value.display.join(', ') : [value.mainText, value.secondaryText].filter(v => !isEmpty(v)).join(', ');
};

export const buildOptionLabel = (placeAddressComponent: PlaceAddressComponent) => {
  const { number, street, city, state, zip, country, district } = placeAddressComponent;

  const streetAddress = [number, street].filter(notEmpty).join(' ');
  const stateAndZip = [state, zip].filter(notEmpty).join(' ');

  return [streetAddress, !isEmpty(city) ? city : district, stateAndZip, country].filter(v => !isEmpty(v)).join(', ');
};
