import { configuration } from '@/core';

import GooglePlaceSearch from './places/GooglePlaceSearch';
import GoogleGeocode from './geocode/GoogleGeocode';
import GoogleDirections from './directions/GoogleDirections';
import GoogleTimezoneAPI from './timezone/GoogleTimezoneAPI';

import { DetailsPlaceAdapter, Result as DetailsPlaceAdapterResult } from './places/adapters/DetailsPlaceAdapter';

import { safeExecute } from '@/libs';
import { Address } from '@/gql/types.generated';

const places = new GooglePlaceSearch(configuration.googleAPIKey);
const timezone = new GoogleTimezoneAPI(configuration.googleAPIKey);
const geocode = new GoogleGeocode(configuration.googleAPIKey);
const directions = new GoogleDirections(configuration.googleAPIKey);

const buildPlace = (value: google.maps.places.PlaceResult | google.maps.GeocoderResult): Partial<Address> | null => {
  return safeExecute(() => new DetailsPlaceAdapter().from(value).toData(), null);
};

const buildPlaceV2 = (value: google.maps.places.Place): Partial<Address> | null => {
  return safeExecute(() => new DetailsPlaceAdapter().fromPlacesV2(value).toData(), null);
};

export { places, geocode, directions, timezone, buildPlace, buildPlaceV2 };
