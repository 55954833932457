import React, { useEffect } from 'react';

export type PageProps = {
  page: PageContextType;
};

export type PageState = {
  renderTitle?: () => React.ReactNode;
  icon?: any;
  name?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  info?: React.ReactNode;
  titleSecondary?: React.ReactNode;
  selectParentId?: string;
};
export type SidebarProps = {
  requestsCount: number;
  payoutRequestCount: number;
};

export type PageContextType = {
  icon: any;
  title: React.ReactNode;
  renderTitle?: () => React.ReactNode;
  titleSecondary?: React.ReactNode;
  subtitle?: React.ReactNode;
  info?: React.ReactNode;
  locked: boolean;
  selectParentId?: string;
  setLocked: (locked: boolean) => void;
  setPageProps: (props: PageState) => void;
};

export type UIContextType = {
  sidebar: SidebarContextType;
  topPanel: TopPanelContextType;
  rootContainer: HTMLDivElement | null;
  setRootContainer: (element: HTMLDivElement | null) => void;
};

export type SidebarContextType = {
  isOpen: boolean;
  setOpened: (opened: boolean) => void;
  props: Partial<SidebarProps>;
  setProps: (props: Partial<SidebarProps>) => void;
  minimized: boolean;
  setMinimized: (minimized: boolean) => void;
};

export type TopPanelContextType = {
  userManagementShown: boolean;
  isTopPanelVisible: boolean;
  showUserManagement: () => void;
  hideUserManagement: () => void;
  setVisibility: (visible: boolean) => void;
  breadcrumb: Breadcrumb;
  setBreadcrumb: (breadcrumb: Breadcrumb) => void;
  clearBreadcrumb: () => void;
};

export type BreadcrumbType = 'contractorInfo' | 'orderInfo' | 'finance' | 'custom' | '';
export type Breadcrumb = { type: BreadcrumbType; text: React.ReactNode | string };

export type ApplicationContextType = {
  page: PageContextType;
  ui: UIContextType;
};

export const ApplicationContext = React.createContext<ApplicationContextType>(undefined as any);

export function useApplicationContext() {
  return React.useContext<ApplicationContextType>(ApplicationContext);
}

export function usePageContext() {
  const context = useApplicationContext();
  return context.page;
}

export function useUIContext(): UIContextType {
  const context = useApplicationContext();
  return context.ui;
}

/**
 * Set breadcrumbs.
 * @param breadCrumbs
 */
export const useBreadCrumbs = (breadCrumbs: Breadcrumb) => {
  const {
    topPanel: { setBreadcrumb, clearBreadcrumb }
  } = useUIContext();

  useEffect(() => {
    setBreadcrumb(breadCrumbs);
    return clearBreadcrumb;
  }, []);

  return { setBreadcrumb, clearBreadcrumb };
};
