import { useCallback } from 'react';
//eslint-disable-next-line no-restricted-imports
import { useHistory } from 'react-router-dom';
import { decodeQueryVariables, encodeQueryVariables } from '@/libs/encodedQuery';
import { currentQuery } from '@/libs/searchQuery';

export const useSetQueryParams: <T = Record<string, any>>() => (
  values: T | ((values: T) => T),
  replace?: boolean
) => void = () => {
  const history = useHistory();

  const setQueryParams = useCallback<(values: any, replace?: boolean) => void>(
    (values, replace = false) => {
      const { f } = currentQuery();
      const variables = typeof values === 'function' ? values(decodeQueryVariables()) : values;
      const newEncodedQuery = encodeQueryVariables(variables);

      if (f !== newEncodedQuery) {
        if (replace) {
          history.replace({ search: `f=${newEncodedQuery}` });
        } else {
          history.push({ search: `f=${newEncodedQuery}` });
        }
      }
    },
    [history]
  );

  return setQueryParams;
};
