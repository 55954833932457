import { Query } from '../Query';

export class NearbySearch extends Query {
  public location!: string;
  /**
   * Up to 50_000 meters
   */
  public radius!: number;

  public type!: string;

  public options: {
    keyword?: string;
    language?: string;
    name?: string;
    type?: string;
    pagetoken?: string;
  } = {};

  public constructor(initialParams: Partial<NearbySearch>) {
    super(initialParams);
    this.name = 'nearbysearch';

    Object.assign(this, initialParams);
  }
}
