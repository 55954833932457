import * as Types from '../../../../gql/types.generated';

import { useSubscription, StackConfig } from "@/shared/codegen/useSubscription"
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useInfinityDataTemplate, InfinityConfig } from "@/shared/codegen/useInfinityDataTemplate"
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnreadchannelsCountListQueryVariables = Types.Exact<{
  unreadOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  partners?: Types.InputMaybe<Array<Types.IdInput> | Types.IdInput>;
}>;


export type UnreadchannelsCountListQuery = { __typename?: 'Query', channels: { __typename?: 'ChannelList', count: number, values: Array<{ __typename?: 'Channel', id: string, lastMessage?: string | null, lastMessageFull?: { __typename?: 'ChannelMessage', text: string, createdAt: string } | null }> } };

export type UnreadMessagesSubscriptionVariables = Types.Exact<{
  unreadOnly?: Types.InputMaybe<Types.Scalars['Boolean']>;
  partners?: Types.InputMaybe<Array<Types.IdInput> | Types.IdInput>;
}>;


export type UnreadMessagesSubscription = { __typename?: 'Subscription', allChannelsChanged?: { __typename?: 'ChannelList', count: number } | null };


export const UnreadchannelsCountListDocument = gql`
    query unreadchannelsCountList($unreadOnly: Boolean, $partners: [IdInput!]) {
  channels(unreadOnly: $unreadOnly, partners: $partners) {
    count
    values {
      id
      lastMessage
      lastMessageFull {
        text
        createdAt
      }
    }
  }
}
    `;

/**
 * __useUnreadchannelsCountListQuery__
 *
 * To run a query within a React component, call `useUnreadchannelsCountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadchannelsCountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadchannelsCountListQuery({
 *   variables: {
 *      unreadOnly: // value for 'unreadOnly'
 *      partners: // value for 'partners'
 *   },
 * });
 */
export function useUnreadchannelsCountListQuery(baseOptions?: Apollo.QueryHookOptions<UnreadchannelsCountListQuery, UnreadchannelsCountListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadchannelsCountListQuery, UnreadchannelsCountListQueryVariables>(UnreadchannelsCountListDocument, options);
      }
export function useUnreadchannelsCountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadchannelsCountListQuery, UnreadchannelsCountListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadchannelsCountListQuery, UnreadchannelsCountListQueryVariables>(UnreadchannelsCountListDocument, options);
        }
export type UnreadchannelsCountListQueryHookResult = ReturnType<typeof useUnreadchannelsCountListQuery>;
export type UnreadchannelsCountListLazyQueryHookResult = ReturnType<typeof useUnreadchannelsCountListLazyQuery>;
export type UnreadchannelsCountListQueryResult = Apollo.QueryResult<UnreadchannelsCountListQuery, UnreadchannelsCountListQueryVariables>;
export const UnreadMessagesDocument = gql`
    subscription unreadMessages($unreadOnly: Boolean, $partners: [IdInput!]) {
  allChannelsChanged(unreadOnly: $unreadOnly, partners: $partners) {
    count
  }
}
    `;

/**
 * __useUnreadMessagesSubscription__
 *
 * To run a query within a React component, call `useUnreadMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadMessagesSubscription({
 *   variables: {
 *      unreadOnly: // value for 'unreadOnly'
 *      partners: // value for 'partners'
 *   },
 * });
 */
export function useUnreadMessagesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UnreadMessagesSubscription, UnreadMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UnreadMessagesSubscription, UnreadMessagesSubscriptionVariables>(UnreadMessagesDocument, options);
      }
export type UnreadMessagesSubscriptionHookResult = ReturnType<typeof useUnreadMessagesSubscription>;
export type UnreadMessagesSubscriptionResult = Apollo.SubscriptionResult<UnreadMessagesSubscription>;


export function useUnreadchannelsCountListRequest() {
    const client = useApolloClient();
    return useCallback<(variables: UnreadchannelsCountListQueryVariables, options?: Omit<Apollo.QueryOptions, 'query'>) => Promise<Apollo.ApolloQueryResult<UnreadchannelsCountListQuery>>>( (variables, options) => {
        return client.query<UnreadchannelsCountListQuery>({
            fetchPolicy: 'no-cache',
          ...options,
          query: UnreadchannelsCountListDocument,
          variables
        })
    }, []);
}


export function useUnreadMessagesWSSubscription(
    messageCb: (item: UnreadMessagesSubscription) => void,
    configs: {
      variables: UnreadMessagesSubscriptionVariables;
      onReopen?: () => void;
      stackConfigs?: StackConfig
    }
  ) {
   return useSubscription< UnreadMessagesSubscription, UnreadMessagesSubscriptionVariables >(
     UnreadMessagesDocument,
     messageCb,
     configs.variables,
     configs.stackConfigs,
     configs.onReopen 
   )
}


export function useListQueries<DataType = UnreadchannelsCountListQuery, Filters = UnreadchannelsCountListQueryVariables>(options?:InfinityConfig<UnreadchannelsCountListQueryVariables, UnreadchannelsCountListQuery,{}, DataType>){
  return useInfinityDataTemplate<UnreadchannelsCountListQuery, {}, {}, {}, UnreadchannelsCountListQueryVariables, {}, {}, {}, DataType, Filters>({
    list: useUnreadchannelsCountListRequest(),
    remove: undefined,
    update: undefined,
    create: undefined,
  }, options)

}

