import { Query } from './Query';
import { Details, NearbySearch, QueryAutoComplete } from './queries';

import { PlaceAdapter } from './adapters/PlaceAdapter';
import { DetailsPlaceAdapter } from './adapters/DetailsPlaceAdapter';
import { Place, Location } from '@/interfaces/brokrete';

const API_ENDPOINT = 'https://maps.googleapis.com/maps/api/place';

export default class GooglePlaceSearch {
  private readonly apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  public queryAutocompleteSearch(
    input: string,
    options: {
      type?: string;
    } = {
      type: 'geocode'
    }
  ): Promise<Place[]> {
    const query: Query = new QueryAutoComplete({
      input: input,
      type: options.type,
      key: this.apiKey
    });

    return this.search(query).then(({ predictions }) =>
      // @ts-ignore
      predictions.map(pr => new PlaceAdapter().fromPrediction(pr).toData())
    );
  }

  public nearbySearch(location: Location, radius = 500, type = 'geocode'): Promise<Place[]> {
    const query: Query = new NearbySearch({
      key: this.apiKey,
      location: `${location.latitude},${location.longitude}`,
      radius: radius,
      type: type
    });

    return this.search(query).then(({ results }) =>
      // @ts-ignore
      results.map(res => new PlaceAdapter().fromNearbyPlace(res).toData())
    );
  }

  public details(placeId: string): Promise<Partial<Place>> {
    const query: Query = new Details({
      key: this.apiKey,
      placeid: placeId
    });

    return this.search(query).then(({ result }) => new DetailsPlaceAdapter().from(result).toData());
  }

  public search(query: Query): Promise<any> {
    return fetch(`${API_ENDPOINT}/${query.apiName}/json?${query.toQuery()}`)
      .then((response: Response) => {
        return response.json();
      })
      .catch((error: any) => {
        throw error;
      });
  }
}
