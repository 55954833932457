import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { formatDate, getTimezone } from '@/shared/tz/getTimzone';
import { useGettext } from '@cranium/i18n';

type TimeProps = {
  date?: string;
  timezone?: string | null;
  format: string;
  deliveryWindow?: number;
  secondaryFormat?: string;
  skipSecondary?: boolean;
};

const StyledText = styled(Typography)`
  line-height: 18px !important;
`;

const SecondaryDate = styled.p`
  margin: 0;
  color: var(--gray-6);
  font: var(--font-regular-10);
  line-height: 10px;
`;

export const Time: FC<TimeProps & HTMLAttributes<HTMLDivElement>> = ({
  secondaryFormat,
  deliveryWindow,
  children,
  date,
  timezone,
  format,
  skipSecondary,
  ...props
}) => {
  const { gettext } = useGettext();
  if (!date || !timezone) return null;
  const time = formatDate(format, date, timezone, deliveryWindow, secondaryFormat);
  const fulltime = formatDate(deliveryWindow ? 'L LT' : 'L LT (z ', date, timezone, deliveryWindow, 'L LT (z ');
  const tz = getTimezone();
  if (!time) return null;
  return (
    <Tooltip
      destroyTooltipOnHide
      title={
        fulltime?.custom ? (
          <table>
            <tr>
              <td>{gettext('Selected timezone')}</td>
              <td>{fulltime?.custom + tz + ')'}</td>
            </tr>
            <tr>
              <td>{gettext('Original timezone')}</td>
              <td>{fulltime?.original + timezone + ')'}</td>
            </tr>
          </table>
        ) : null
      }
    >
      <div {...props}>
        <StyledText className="bold-12 gray-10">{time.custom || time.original}</StyledText>
        {time.custom && !skipSecondary ? <SecondaryDate>{time.original}</SecondaryDate> : null}
        {children}
      </div>
    </Tooltip>
  );
};
