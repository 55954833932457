import { Query } from '../Query';
import { Timezone } from './queries/Timezone';
import { Location } from '@/gql/types.generated';

const API_ENDPOINT = 'https://maps.googleapis.com/maps/api/timezone';

type TimezoneAPIResponse = {
  dstOffset: number;
  rawOffset: number;
  status: string;
  timeZoneId: string;
  timeZoneName: string;
};

export default class GoogleTimezoneAPI {
  private readonly apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  public timezone(location: Location, timestamp: number): Promise<TimezoneAPIResponse> {
    const query: Query = new Timezone({
      key: this.apiKey,
      location,
      timestamp
    });

    return this.execute(query).then(response => {
      return response;
    });
  }

  private execute(query: Query): Promise<any> {
    return fetch(`${API_ENDPOINT}/json?${query.toQuery()}`)
      .then((response: Response) => {
        return response.json();
      })
      .catch((error: any) => {
        throw error;
      });
  }
}
