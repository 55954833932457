import { Query } from '../Query';

export class ByLocation extends Query {
  /**
   * The text input specifying which place to search for
   */
  public latlng!: string;

  public result_type!: string;

  /**
   * Optional parameters
   */
  public options: {} = {};

  public constructor(initialParams: Partial<ByLocation>) {
    super(initialParams);

    Object.assign(this, initialParams);
  }
}
