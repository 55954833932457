import { createContext, useMemo, useContext, FC, ReactNode, useState, useEffect, useCallback } from 'react';
import { useAccessPermissionsRequest, AccessPermissionsQuery } from './queries/permissions.generated';
import { useGetParnterTokenRequest, GetParnterTokenMutation } from './queries/singin.generated';
import get from 'lodash/get';
import type { IdentityTypeEnum } from '@/gql/types.generated';
import type { PermissionsType } from './PermissionTypes';
import type { ClientSettings } from './SettingsTypes';
import { AllNodesFor, NestedKeyOf, PathValue, VisiblePaths } from './utils';
import { getAccountfromUrl } from '@/core/getAccountfromUrl';
import { LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT, LocalStorage } from '@/core/Storage';
import { setSentryContext, setSentryTag, setSentryUser } from '@/errorMonitoring';

function setupSentryMetaData(meData: Partial<AccessPermissionsQuery['me']> | null | undefined) {
  setSentryUser({
    email: meData?.partnerMember?.email || undefined,
    id: meData?.partnerMember?.id
  });
  setSentryContext('partner', meData?.partner);
  setSentryTag('userType', meData?.type);
  setSentryTag('partnerID', meData?.partner?.id);
  setSentryTag('applicationName', meData?.partner?.name);
}

type PermissionsValue = {
  loading: boolean;
  refreshing: boolean;
  permissions?: null | { permissions: PermissionsType; settings: ClientSettings };
  partner?: AccessPermissionsQuery['me']['partner'];
  meData?: Omit<AccessPermissionsQuery['me'], 'permissionsNew' | 'settings'> | null;
};

type ExtractKeys<T> = T[keyof T];
type Keys = ExtractKeys<{
  [K in keyof NonNullable<PermissionsValue['permissions']>]: K extends 'permissions'
    ? AllNodesFor<Omit<NonNullable<PermissionsValue['permissions']>, 'settings'>>
    : K extends 'settings'
    ? VisiblePaths<Omit<NonNullable<PermissionsValue['permissions']>, 'permissions'>>
    : never;
}>;

type PermissionMethods = {
  prefetchPartnerData: (id: string, type: IdentityTypeEnum, authData?: GetParnterTokenMutation) => Promise<any>;
};

export function getAccoutKey(prefix: string) {
  const accountID = getAccountfromUrl(window.location.pathname);
  return [prefix, accountID].filter(Boolean).join('-');
}
export const PermissionsContext = createContext<PermissionsValue & PermissionMethods>({
  loading: false,
  refreshing: true,
  partner: null,
  permissions: null,
  meData: null,
  prefetchPartnerData: () => Promise.resolve()
});

/**
 *
 * @visibleName Access
 */
export const PermissionsProvider: FC = ({ children }) => {
  const getPermission = useAccessPermissionsRequest();
  const getPartnerToken = useGetParnterTokenRequest();
  const [permissions, setPermissions] = useState<PermissionsValue>(() => {
    const cacheData: any = LocalStorage.getItem(getAccoutKey('account'));
    return {
      ...(cacheData || { permissions: null, partner: null, meData: null }),
      refreshing: true,
      loading: !cacheData
    };
  });

  useEffect(() => {
    function cb() {
      getPermission({}).then(resp => {
        if (resp.errors) {
          return Promise.reject(resp.errors);
        }
        const { permissionsNew: permissions, settings, ...meData } = resp?.data?.me || {};

        setupSentryMetaData(meData);
        const partner = resp?.data.me.partner;
        if (resp?.data?.me?.partnerMember?.workingTimezone) {
          LocalStorage.setItem(`tz-${partner?.id}`, resp?.data?.me?.partnerMember?.workingTimezone || partner?.timezone || '');
        }
        setPermissions({ refreshing: false, loading: false, permissions: { permissions, settings }, partner, meData });

        LocalStorage.setItem(`account-${partner?.id}`, { permissions: { permissions, settings }, partner, meData });
      });
    }
    document.body.addEventListener('REFETCH_ROOT_PARTNER_DATA', cb);
    return () => {
      document.body.removeEventListener('REFETCH_ROOT_PARTNER_DATA', cb);
    };
  }, []);

  useEffect(() => {
    function getToken(): Promise<string | null | undefined> {
      return new Promise((res, rej) => {
        const token = LocalStorage.getItem<string>(getAccoutKey('token'));
        if (token) {
          return res(token);
        }
        setPermissions({ refreshing: false, loading: true, permissions: null, partner: null });
        if (!LocalStorage.getItem('identityType') || !getAccountfromUrl(window.location.pathname)) {
          const prevLocation =
            LocalStorage.getItem<string>('location.beforeLogin') ||
            [window.location.pathname, window.location.search].filter(Boolean).join('');
          if (prevLocation && !prevLocation.includes('login')) {
            LocalStorage.setItem('location.beforeLogin', prevLocation);
          }
          return rej('can not check access');
        }
        return getPartnerToken(
          {
            partner: { id: getAccountfromUrl(window.location.pathname) as string },
            type: LocalStorage.getItem('identityType') as IdentityTypeEnum
          },
          {
            context: {
              headers: {
                Authorization: LocalStorage.getItem('token')
              }
            }
          }
        )
          .then(resp => {
            if (resp?.errors) {
              return Promise.reject(resp);
            }
            res(resp?.data?.authSignIn?.token);
          })
          .catch(rej);
      });
    }
    getToken()
      .then((resp?: string | null) => {
        return getPermission(
          {},
          {
            context: {
              skipTokenSave: true,
              headers: {
                Authorization: resp
              }
            }
          }
        );
      })
      .then(resp => {
        if (resp.errors) {
          return Promise.reject(resp.errors);
        }
        const { permissionsNew: permissions, settings, ...meData } = resp?.data?.me || {};
        setupSentryMetaData(meData);
        const partner = resp?.data.me.partner;
        if (resp?.data?.me?.partnerMember?.workingTimezone) {
          LocalStorage.setItem(`tz-${partner?.id}`, resp?.data?.me?.partnerMember?.workingTimezone || partner?.timezone || '');
        }
        setPermissions({ refreshing: false, loading: false, permissions: { permissions, settings }, partner, meData });

        LocalStorage.setItem(`account-${partner?.id}`, { permissions: { permissions, settings }, partner, meData });
      })
      .catch(err => {
        setPermissions({ refreshing: false, loading: false, permissions: null, partner: null, meData: null });
      });
    function logout() {
      setPermissions({ refreshing: false, loading: false, permissions: null, partner: null, meData: null });
    }
    document.addEventListener(LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT, logout);
    return () => {
      document.removeEventListener(LOCAL_STORAGE_SUBSCRIBE_CLEAR_EVENT, logout);
    };
  }, []);

  const prefetchPartnerData = useCallback(
    (id: string, type: IdentityTypeEnum, authData?: GetParnterTokenMutation) => {
      function getToken() {
        if (authData && authData?.authSignIn?.partner?.id === id) {
          return Promise.resolve({ data: authData });
        }
        return getPartnerToken(
          {
            partner: { id },
            type
          },
          {
            context: {
              headers: {
                Authorization: LocalStorage.getItem('token')
              }
            }
          }
        ).then(resp => (resp?.errors ? Promise.reject(resp?.errors) : resp));
      }
      return getToken()
        .then(resp => {
          return getPermission(
            {},
            {
              context: {
                skipTokenSave: true,
                headers: {
                  Authorization: resp?.data?.authSignIn?.token
                }
              }
            }
          );
        })
        .then(resp => {
          if (resp.errors) {
            return Promise.reject(resp.errors);
          }
          const { permissionsNew: permissions, settings, ...meData } = resp?.data?.me || {};
          setupSentryMetaData(meData);
          const partner = resp?.data.me.partner;
          if (resp?.data?.me?.partnerMember?.workingTimezone) {
            LocalStorage.setItem(`tz-${partner?.id}`, resp?.data?.me?.partnerMember?.workingTimezone || partner?.timezone || '');
          }
          setPermissions({ refreshing: false, permissions: { permissions, settings }, partner, meData, loading: false });
          LocalStorage.setItem(`account-${id}`, { permissions: { permissions, settings }, partner, meData });
        });
    },
    [getPartnerToken, getPermission]
  );

  return <PermissionsContext.Provider value={{ ...permissions, prefetchPartnerData }}>{children}</PermissionsContext.Provider>;
};

export function usePermissions() {
  return useContext(PermissionsContext).permissions?.permissions || ({} as PermissionsType);
}

export function useUserData() {
  return useContext(PermissionsContext).meData;
}
export function usePartnerData() {
  return useContext(PermissionsContext).partner;
}

export function usePrefetchPartnerData() {
  return useContext(PermissionsContext).prefetchPartnerData;
}

export function useHasPermissions(permissions: Keys | Keys[], operator?: 'ALL' | 'SOME'): boolean {
  const userPermissions = useContext(PermissionsContext).permissions;
  return useMemo(() => {
    if (Array.isArray(permissions)) {
      const operation = operator === 'SOME' ? 'some' : 'every';
      return permissions[operation](level => get(userPermissions, level));
    }
    return get(userPermissions, permissions) || false;
  }, [userPermissions, permissions, operator]);
}

export const useClientSettings = (): ClientSettings => {
  return useContext(PermissionsContext).permissions?.settings || ({} as ClientSettings);
};

export function useGetClientSettings<P extends NestedKeyOf<T>, T = ClientSettings>(key: P, defaultValue?: any): PathValue<T, P> {
  const settings = useContext(PermissionsContext).permissions?.settings || ({} as ClientSettings);

  return get(settings, key, defaultValue);
}

export interface PermissionsGateProps {
  permissions: Keys | Keys[];
  operator?: 'ALL' | 'SOME';
  fallback?: ReactNode;
}
