import { useUIContext } from '@components/context/ApplicationContext';
import { useGettext } from '@cranium/i18n';
import useBackgroundFetcher from '@pages/RequestsPage/useBackgroundFetcher';
import React, { useEffect } from 'react';
import { NavLinkItem } from '@components/layout/Sidebar/components/NavLinkItem';
import { useLocation } from 'react-router-dom';
import { useUnreadMessagesCountFetcher } from '@pages/Messages/hooks/useUnreadMessagesCountFetcher';
import { usePartnerData } from '@/shared/access';

export const RequestNavItem = () => {
  const uiContext = useUIContext();
  const { gettext } = useGettext();
  const [{ all }] = useBackgroundFetcher();

  useEffect(() => {
    uiContext.sidebar.setProps({ requestsCount: all });
  }, [all]);

  return (
    <NavLinkItem
      iconClass="icon-request"
      label={gettext('Request')}
      path="/requests"
      count={uiContext.sidebar.props.requestsCount}
      userTipName="sidebar.requests"
    />
  );
};

export const MessagesNavItem = () => {
  const { gettext } = useGettext();
  const partnerData = usePartnerData();
  const count = useUnreadMessagesCountFetcher(partnerData?.id ? { partners: [{ id: partnerData?.id }] } : {});

  return (
    <NavLinkItem
      iconClass="icon-message-1"
      label={gettext('Messages')}
      path="/messages"
      count={count || undefined}
      userTipName="sidebar.messages"
    />
  );
};

export const PayoutRequestNavItem = () => {
  const { pathname } = useLocation();
  const { gettext } = useGettext();
  const { sidebar } = useUIContext();
  const renderCount = !pathname.includes('finance') ? sidebar.props.payoutRequestCount : undefined;

  return (
    <NavLinkItem
      iconClass="icon-finance"
      label={gettext('Finance')}
      path="/finance"
      count={renderCount}
      userTipName="sidebar.finance"
    />
  );
};
