import { AddressComponent, AddressObject } from '../../lib/AddressObject';
import { FormattedAddress } from '../../lib/FormattedAddress';
import { PlaceAddressComponent } from '@shared-core/google/lib/PlaceAddressComponent';
import { Address } from '@/gql/types.generated';

export interface Result {
  place_id: string;

  address_components: Array<AddressComponent>;

  formatted_address: string;

  geometry: {
    location: {
      lat: () => number | number;
      lng: () => number | number;
    };
  };

  types: Array<string>;
}

export class DetailsPlaceAdapter {
  private placeId: string | undefined;
  private location:
    | {
        latitude: number;
        longitude: number;
      }
    | undefined;

  private mainText: string | undefined;
  private secondaryText: string | undefined;

  private state: string | undefined;
  private country: string | undefined;
  private city: string | undefined;
  private zip: string | undefined;

  public from(result: google.maps.places.PlaceResult): DetailsPlaceAdapter {
    this.placeId = result.place_id;
    if (result.geometry?.location) {
      this.location = {
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng()
      };
    }

    if (result.address_components) {
      const addressObject = new AddressObject(result.address_components);
      this.state = addressObject.state;
      this.country = addressObject.country;
      this.city = addressObject.city;
      this.zip = addressObject.zip;

      if (result.formatted_address) {
        const formattedAddress = new FormattedAddress(result.formatted_address, addressObject);

        this.mainText = formattedAddress.main;
        this.secondaryText = formattedAddress.secondary;
      }
    }

    return this;
  }

  public fromPlacesV2(place: google.maps.places.Place): DetailsPlaceAdapter {
    this.placeId = place.id;
    if (place.location) {
      this.location = {
        latitude: place.location.lat(),
        longitude: place.location.lng()
      };
    }

    if (place.addressComponents) {
      const addressObject = new PlaceAddressComponent(place.addressComponents);
      this.state = addressObject.state;
      this.country = addressObject.country;
      this.city = addressObject.city;
      this.zip = addressObject.zip;

      if (place.formattedAddress) {
        const formattedAddress = new FormattedAddress(place.formattedAddress, addressObject);

        this.mainText = formattedAddress.main;
        this.secondaryText = formattedAddress.secondary;
      }
    }

    return this;
  }

  public toData(): Partial<Address> {
    return {
      id: this.placeId,
      mainText: this.mainText,
      secondaryText: this.secondaryText,
      location: this.location
        ? {
            latitude: this.location.latitude,
            longitude: this.location.longitude
          }
        : undefined,
      display: [[this?.mainText, this?.secondaryText].filter(Boolean).join(', ')],
      state: this.state,
      country: this.country,
      city: this.city,
      zip: this.zip
    };
  }
}
