import {
  UnreadchannelsCountListQuery,
  UnreadMessagesSubscriptionVariables,
  useListQueries,
  useUnreadMessagesWSSubscription
} from '@pages/Messages/hooks/sidebarMessages.generated';

function transformValue(resp?: UnreadchannelsCountListQuery) {
  return resp?.channels?.count;
}

export const useUnreadMessagesCountFetcher = (props?: Partial<UnreadMessagesSubscriptionVariables>) => {
  const { data, setData } = useListQueries<ReturnType<typeof transformValue>>({
    transformValue,
    fetchOnMount: true,
    useUrlQuery: false,
    filters: {
      ...props,
      unreadOnly: true
    }
  });

  useUnreadMessagesWSSubscription(
    message => {
      if (message?.allChannelsChanged) {
        setData(() => message?.allChannelsChanged?.count || 0);
      }
    },
    {
      variables: {
        ...props,
        unreadOnly: true
      }
    }
  );
  return data;
};
